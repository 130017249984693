.audit-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
}

.audit-row {
    width: 100%;
    border-bottom: 1px #D1D5DB solid;
}

.audit-row td {
    padding-left: 0;
    font-size: 14px;
    padding: 18px 0 12px 0;
}

.audit-row td:first-child {
    max-width: 100px;
    font-weight: 600;
}

.audit-header-container button {
    background: transparent;
    min-width: auto;
}