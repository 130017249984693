.pdf-viewer-container {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-viewer {
    width: 100%;
    height: 100%;
    border: none;
}