.report-area {
    padding-bottom: 64px;
    border-bottom: 1px solid#D1D5DB;
}

.report-area-dropdown {
    margin-left: 0;
    height: 40px;
}

.range-picker {
    display: flex;
    justify-content: space-between;
    column-gap: 48px;
    margin-top: 24px;
}

.report-date {
    height: 40px;
    border-bottom: 1px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 14px;
    font-family: Roboto;
    color: #6B7280;
    background-color: #F9FAFB;
    width: 100%;
}

.start-date__container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.error {
    border: 1px solid red;
}

.error-message {
    color: red;
    font-size: 12px;
}

.field-label {
    color: #6B7280;
    font-size: 12px;
    font-weight: 500;
}