.audit-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: rgb(31, 41, 55, .7);
    z-index: 20;
  }

.audit__container{
    height: fit-content;
    width: 65%;

    border-radius: 16px;
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* position: absolute; */
    position: fixed;
    z-index: 5;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #F3F4F6;
    padding: 24px 0 48px;
}

.audit-content__container{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;
}

.audit-table {
    border-collapse: collapse;
    margin-right: 28px;
}
