.report-footer {
    padding: 48px;
    display: flex;
    width: 100%;
}

.report-footer__action_btns {
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1;
}

.generate-btn {
    padding: 0 24px;
}