thead {
  height: 34px;
  background-color: #fff;
}

.policy-claims-table-heading {
  background: #E5E7EB;
  border-radius: 6px;
  padding: 8px 24px;
}

.policy-claims-table-heading p {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #1F2937;
  margin: 0;
}

.policy-claim-data-p {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1F2937;
}

.sub-heading {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #4B5563;
  margin: 0;
}

.policy-claims__no-claims {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 254px;
  background: rgba(249, 250, 251, 0.5);
  border: 1px dashed #9CA3AF;
  border-radius: 8px;
  margin: 24px 0;
}

.policy-claims__no-claims-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #4B5563;
  margin-top: 16px;
}

.receipt {
  text-align: center;
}

.receipt-btn {
  min-width: fit-content;
  font-size: 12px;
  padding: 4px 12px;
  height: auto;
  background: white;
  border: 1px solid #e5e7eb;
}

.is-disabled {
  cursor: not-allowed;
  background: #e5e7eb;
  color: #727985;
}