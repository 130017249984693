.reports-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.loading-container {
    padding: 0 48px 48px 48px;
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
}