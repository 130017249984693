.download-area {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border: 1px dashed #D1D5DB;
    border-radius: 12px;
}

.downlaod-csv__btn {
    color: #fff;
    background-color: #65a30d;
    text-decoration: none;
    padding: 12px 24px;
    border-radius: 6px;
}